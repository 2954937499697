import React from 'react';
import styled from 'styled-components';
import {useOutlet} from 'reconnect.js';
import {Map} from '@styled-icons/boxicons-solid/Map';
import {Clock} from '@styled-icons/fa-regular/Clock';
import {People} from '@styled-icons/bootstrap/People';
import {CurrencyDollar} from '@styled-icons/bootstrap/CurrencyDollar';
import {ArrowRight} from '@styled-icons/heroicons-solid/ArrowRight';
import Config from '../../../../data.json';
import Empty from '../../../Components/Empty';
import * as AppActions from '../../../AppActions';
import {mapLineBreak} from '../../../Utils/TextUtil';

function getDayDisplay(dateStr) {
  let day = new Date(dateStr).getDay();
  if (day === 0) {
    return '日';
  } else if (day === 1) {
    return '一';
  } else if (day === 2) {
    return '二';
  } else if (day === 3) {
    return '三';
  } else if (day === 4) {
    return '四';
  } else if (day === 5) {
    return '五';
  } else if (day === 6) {
    return '六';
  }
}

function ProductGrid(props) {
  const {products, onItemClick, prefixPath} = props;
  const [categroies] = useOutlet('categories');
  const [articleCategories] = useOutlet('articleCategories');
  const [dimension] = useOutlet('dimension');
  const mobile = dimension.rwd === 'mobile';

  if (products.length <= 0) {
    return <Empty />;
  }

  return (
    <ProductGridWrapper mobile={mobile} className="ProductGrid">
      {products.map((product, i) => {
        return <ProductItem product={product} />;
      })}
    </ProductGridWrapper>
  );
}

const ProductGridWrapper = styled.div`
  margin: ${(props) => (props.mobile ? 0 : '15px 20px')};
  border-top: 5px solid var(--primaryColor);
  padding-top: 30px;
  padding-bottom: 30px;

  display: grid;
  grid-template-columns: repeat(auto-fit, 250px);
  justify-content: space-around;
  grid-gap: 25px 10px;
`;

function ProductItem(props) {
  const {product} = props;

  return (
    <ProductItemWrapper
      onClick={() => AppActions.navigate(`/product?id=${product.id}`)}>
      <div className="main">
        <div className="date">
          {product.session &&
          Object.keys(product.session).length > 0 &&
          product.session.date
            ? `${product.session.date} (${getDayDisplay(product.session.date)})`
            : '線上自由安排'}
        </div>
        <div className="name">{product.name}</div>
      </div>

      <div className="information">
        <div className="item">
          <div className="price-container">
            <span className="name">費用</span>
            <span className="price">${product.price}</span>
          </div>

          <div className="price-container">
            {product.tba_enable_special_member && (
              <span>特別會員 ${product.tba_special_member_price} </span>
            )}
            {product.tba_enable_intern_member && (
              <span>實習律師 ${product.tba_intern_member_price} </span>
            )}
            {product.tba_enable_new_member && (
              <span>新進會員 ${product.tba_new_member_price} </span>
            )}
            {product.tba_enable_extra_member && (
              <span>其他人員 ${product.tba_extra_member_price} </span>
            )}
            {product.tba_enable_twba_member && (
              <span>全律會會員 ${product.tba_twba_member_price} </span>
            )}
          </div>
        </div>
      </div>

      <div className="keyword">
        <div
          className="text"
          dangerouslySetInnerHTML={{
            __html: mapLineBreak(product.searchText),
          }}
        />
      </div>

      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
        }}>
        <ArrowRight size={20} color={Config.themeColorSecond} />
        <span
          style={{
            paddingLeft: 3,
            paddingBottom: 2,
          }}>
          查看詳情
        </span>
      </div>
    </ProductItemWrapper>
  );
}

const ProductItemWrapper = styled.div`
  cursor: pointer;
  padding: 20px;
  width: 100%;
  
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  border-radius: 10px;

  & > .main {
    border-bottom: 3px solid #c8c8c8;

    & > .date {
      margin-bottom: 15px;
      font-weight: 600;
      font-size: 1.2rem;
      color: var(--primaryColor);
    }

    & > .name {
      margin-bottom: 15px;
      font-weight: 600;
      font-size: 1.15rem;
    }

    & > .author {
      margin-bottom: 15px;
      font-size: 1rem;
    }
  }

  & > .information {
    border-bottom: 3px solid #c8c8c8;
    margin-bottom: 15px;

    padding-top: 10px;
    padding-bottom: 10px;

    & > .item {
      padding: 2px 0px;

      & > .price-container {
        & > .name {
          padding-right: 5px;
        }

        & > .price {
          margin-bottom: 5px;
          font-weight: 600;
          font-size: 1.25rem;
          color: var(--secondColor);
        }
      }
  }

  & > .keyword {
    margin-bottom: 15px;
  }
`;

export default ProductGrid;

import React from 'react';
import ProductAddonDetail from './addon';
import ProductGroupDetail from './group';
import qs from 'query-string';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import withPageEntry from '../../withPageEntry';
import * as AppActions from '../../AppActions';

function getProductIdFromLocation(location) {
  const params = qs.parse(location.search);
  return params.id;
}

function removeTbaSeatsMoreThanStockAmountOptions(product) {
  let nextProduct = {...product};

  if (
    nextProduct.stock_type !== 'always' &&
    Array.isArray(nextProduct.variants) &&
    nextProduct.variants.length > 0
  ) {
    for (let i = 0; i < nextProduct.variants.length; i++) {
      const variant = nextProduct.variants[i];
      const choices = variant.choices || [];
      const stock_amount =
        (nextProduct.stock_amount || 0) - (nextProduct.stock_sold_amount || 0);

      for (let n = 0; n < choices.length; n++) {
        const choice = choices[n];
        const tba_seat = choice.tba_seat || 0;

        if (
          tba_seat > 0 &&
          stock_amount > 0 &&
          choice.tba_seat > stock_amount
        ) {
          nextProduct.variants[i].choices.splice(n, 1);
        }
      }
    }
  }

  return nextProduct;
}

function ProductDetail(props) {
  const {
    pageContext: {prebuiltProduct = null},
  } = props;
  const id = getProductIdFromLocation(props.location);
  const [product, setProduct] = React.useState(prebuiltProduct);
  const [nIn1Product, setNIn1Product] = React.useState(null);

  React.useEffect(() => {
    async function fetchData() {
      AppActions.setLoading(true);
      try {
        const product = await JStorage.fetchOneDocument('product', {id}, null, {
          anonymous: true,
        });

        setProduct(removeTbaSeatsMoreThanStockAmountOptions(product));

        if (product.type === 'default') {
          const nIn1Product = await JStorage.fetchOneDocument('product', {
            type: 'n_in_1',
            n_in_1_products: product.id,
          });

          setNIn1Product(nIn1Product);
        }
      } catch (ex) {
        console.warn('ProductDetail.useEffect ex', ex);
      }
      AppActions.setLoading(false);
    }

    if (!prebuiltProduct && id) {
      fetchData();
    }
  }, [id, prebuiltProduct]);

  if (!prebuiltProduct && !product) {
    return <div style={{minHeight: 'calc(100vh)'}} />;
  }

  if (product.type === 'group_root') {
    return <ProductGroupDetail {...props} product={product} />;
  } else {
    return (
      <ProductAddonDetail
        {...props}
        product={product}
        nIn1Product={nIn1Product}
      />
    );
  }
}

export default withPageEntry(ProductDetail);

import React from 'react';
import styled from 'styled-components';
import {Modal} from 'antd';
import {getNewOutlet, getOutlet, useOutlet} from 'reconnect.js';
import {InfoCircleFilled} from '@ant-design/icons';

getNewOutlet('ProudctConfirmModal', null, {autoDelete: false});

export function showProductConfirmModal(data) {
  getOutlet('ProudctConfirmModal').update(data);
}

export default function ProductComfirmModal(props) {
  const [data, setData] = useOutlet('ProudctConfirmModal');

  return (
    <Modal
      title={
        <h3>
          <InfoCircleFilled style={{marginRight: 6, color: '#B49664'}} />
          <span style={{color: '#B49664'}}>請確認您的報名資訊</span>
        </h3>
      }
      width={400}
      visible={!!data}
      okText="確認，前往報名"
      cancelText="取消"
      onOk={async () => {
        if (data && data.onOk && typeof data.onOk === 'function') {
          await data.onOk();
        }

        setData(null);
      }}
      onCancel={() => {
        setData(null);
      }}>
      <ModalContent data={data} setData={setData} />
    </Modal>
  );
}

function ModalContent(props) {
  const {data, setData} = props;

  return (
    <ModalContentWrapper>
      <div className="container">
        <div className="title">{data.product.name}</div>
        <div className="content">
          {data.product.session &&
            Object.keys(data.product.session).length > 0 && (
              <div>
                <div>
                  已選擇場次：{data.product.session.date}{' '}
                  {data.product.session.start_time}-
                  {data.product.session.end_time}
                </div>
                {data.product.session.place && (
                  <div>地點：{data.product.session.place}</div>
                )}
              </div>
            )}
          {data.currItemConfig &&
            Array.isArray(data.currItemConfig.variants) &&
            data.currItemConfig.variants.length > 0 &&
            data.currItemConfig.variants.map((variant) => {
              const targetVariant = data.product.variants.find(
                (v) => v.name === variant.name,
              );
              const targetChoice = targetVariant?.choices.find(
                (c) => c.name === variant.choice,
              );

              if (targetVariant && targetChoice) {
                return (
                  <div>
                    <div>
                      {targetVariant.name}：{targetChoice.name}
                    </div>
                    <div>
                      {targetChoice.tba_seat > 0 &&
                        `報名人數：${targetChoice.tba_seat} 人`}
                    </div>
                  </div>
                );
              }

              return (
                <div>
                  {variant.name}：{variant.choice}
                </div>
              );
            })}
          <div>總金額：NT$ {data.currPrice || data.product.price}</div>
        </div>
      </div>
    </ModalContentWrapper>
  );
}

const ModalContentWrapper = styled.div`
  & > .container {
    padding: 20px;

    & > .title {
      font-size: 18px;
      font-weight: 500;
      color: #434343;
      margin-bottom: 10px;
      
    }

    & > .content {
      font-size: 16px;
      line-height 30px;
    }
  }
`;

import React from 'react';
import * as AppActions from '../../AppActions';
import ProductGrid from '../../Templates/ProductList/ProductGrid';
import BrowseHeader from '../../Components/BrowseHeader';
import * as Ant from 'antd';
import expertises from '../../expertises.json';

const ExpOptions = [
  {label: '不指定', value: ''},
  ...expertises.map((e) => ({
    label: e,
    value: e,
  })),
];

export function getExtraProductListProps(props) {
  let topFilter = hasCustomTopFilter(props.location.pathname);
  let filterMenuType = getCustomFilterMenuType(props.location.pathname);

  let onCustomClick = async (item) => {
    if (props.location.pathname.indexOf('/products') > -1) {
      await AppActions.navigate(`/product?id=${item.id}`, {loading: true});
    } else if (props.location.pathname.indexOf('/articles') > -1) {
      await AppActions.navigate(`/article?id=${item.id}`, {loading: true});
    }
  };

  function renderCustomSection({route, sectionId, params}) {
    if (sectionId === 'A') {
      return <BrowseHeader route={route} cat={params.cat} />;
    } else if (sectionId === 'E') {
      return (
        <div style={{paddingLeft: 20}}>
          <h1>課程活動</h1>
        </div>
      );
    } else if (sectionId === 'F') {
      return (
        <div
          style={{
            padding: '0px 20px 20px 20px',
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
          }}>
          <div style={{color: '#3e3e3e', marginRight: 10}}>依照領域選擇</div>
          <Ant.Select
            defaultValue={''}
            value={params.q || ''}
            onChange={(q) => {
              let qStr = '';
              if (params.cat) {
                qStr += `cat=${params.cat}&`;
              }
              if (params.page) {
                qStr += `page=${params.page}&`;
              }
              if (q && q !== '---') {
                qStr += `q=${q}&`;
              }
              if (qStr) {
                qStr = '?' + qStr.slice(0, -1);
              }
              AppActions.navigate(`/products/${qStr}`);
            }}
            style={{maxWidth: 280, width: '100%'}}>
            {ExpOptions.map((opt) => {
              return (
                <Ant.Select.Option value={opt.value} key={opt.value}>
                  {opt.label}
                </Ant.Select.Option>
              );
            })}
          </Ant.Select>
        </div>
      );
    }
    return null;
  }

  return {
    ...props,
    ProductGrid,
    topFilter,
    filterMenuType,
    onCustomClick,
    renderCustomSection,
  };
}

export function getExtraProductDetailPage(props) {
  let filterMenuType = getCustomFilterMenuType(props.location.pathname);

  function renderCustomSection(sectionId, params) {
    return null;
  }

  return {
    filterMenuType,
    renderCustomSection,
  };
}

export function getCustomFilterMenuType(pathname) {
  if (pathname.indexOf('/product') > -1) {
    return 'L3';
  } else if (pathname.indexOf('/article') > -1) {
    return 'L1';
  }
  return null;
}

export function hasCustomTopFilter(pathname) {
  if (pathname.indexOf('/article') > -1) {
    return true;
  }
  return false;
}

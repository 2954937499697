import * as SelectOptionLabelUtil from './SelectOptionLabelUtil';

function getProductMeetingRoomsLabel(product) {
  const isSession =
    product.session &&
    Object.keys(product.session).length > 0 &&
    product.session.date &&
    product.session.start_time &&
    product.session.end_time;

  const hasRoomsValue =
    isSession &&
    Array.isArray(product.session.rooms) &&
    product.session.rooms.length > 0;

  const hasRoomValue = isSession && product.session.room;

  return hasRoomValue
    ? SelectOptionLabelUtil.getMeetingRoomLabel(product.session.room)
    : hasRoomsValue
    ? `${(() => {
        let text = '';

        for (let i = 0; i < product.session.rooms.length; i++) {
          text += `${product.session.rooms[i]}${
            i + 1 !== product.session.rooms.length ? '、' : ''
          }`;
        }

        return text;
      })()}`
    : '';
}

export {getProductMeetingRoomsLabel};
